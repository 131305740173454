import React from 'react';
import type { GatsbyBrowser } from 'gatsby';
import Layout from './src/components/layout';

// Flag to detect "popstate" (browser back/forward button)
let isPop = false;
// Storage for scroll positions, keyed by location.key or pathname
const scrollPositions: Record<string, number> = {};

// Listen for back/forward navigation events in the browser
if (typeof window !== 'undefined') {
  window.addEventListener('popstate', () => {
    isPop = true;
  });
}

export const onClientEntry: GatsbyBrowser['onClientEntry'] = () => {
  // Disable automatic browser scroll restoration
  if (typeof window !== 'undefined' && 'scrollRestoration' in window.history) {
    window.history.scrollRestoration = 'manual';
  }
};

// Save the current scroll position before navigating away
export const onPreRouteUpdate: GatsbyBrowser['onPreRouteUpdate'] = ({
  prevLocation,
}) => {
  if (prevLocation) {
    const key = prevLocation.key || prevLocation.pathname;
    scrollPositions[key] = window.scrollY;
  }
};

// On route change, decide whether to restore previous scroll or jump to top
export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({ location }) => {
  const key = location.key || location.pathname;
  if (isPop && typeof scrollPositions[key] === 'number') {
    // Back/forward navigation: restore the previous scroll
    setTimeout(() => window.scrollTo(0, scrollPositions[key]), 50);
  } else {
    // Forward navigation: scroll to top
    setTimeout(() => window.scrollTo(0, 0), 50);
  }
  isPop = false;
};

// Wrap each page in Layout
export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
  props,
}) => {
  return <Layout {...props}>{element}</Layout>;
};