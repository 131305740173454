import React from 'react';
import { Link } from 'gatsby';
import * as styles from './secondary-nav.module.scss';
import ContactIcon from '../assets/icons/phone.svg';
import UsersIcon from '../assets/icons/users.svg';

const SecondaryNav: React.FC = () => (
  <div className={`${styles.secondaryNav} is-hidden-mobile`}>
    <div className="container">
      <div className={styles.secondaryNavContent}>
            <span className="icon-text">
              <span className="icon">
                <UsersIcon /> 
              </span>
              <Link to="/o-workflow/" className={styles.link}>
                O nás
            </Link>
          </span>
      </div>
      <div className={styles.secondaryNavContent}>
            <span className="icon-text">
              <span className="icon">
                <ContactIcon />
              </span>
              <Link to="/kontakt/" className={styles.link}>
                Kontakt
            </Link>
          </span>
      </div>
    </div>
  </div>
);

export default SecondaryNav; 